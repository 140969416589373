import React from 'react';

import SEO from '../components/seo';
import errorGif from '../images/error_404.gif';
import Layout from '../components/layout/Layout';

export default () => (
  <Layout>
    <SEO title="BPT - 404 Not Found" />
    <section className="py-20 md:py-32">
      <div className="container mx-auto px-8 flex items-center justify-center">
        <img
          className="flex-grow md:flex-grow-0 md:max-w-lg rounded-lg"
          src={errorGif}
          alt="Error 404"
        />
      </div>
    </section>
  </Layout>
);
